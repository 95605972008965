.row-disabled {
    background-color: gray;
    opacity: 0.8;
  }
  
  .tableScroll1 ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    
  }

  @media (min-width: 900px) { 
    .tableScroll1 ::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
  }
  
  
  @media (min-width: 1200px) {
    .tableScroll1 ::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
  }
  
  @media (min-width: 1536px) {
    .tableScroll1 ::-webkit-scrollbartml {
      height: 10px;
      width: 10px;
    }
  }
  
  
  /* Track */
  .tableScroll1 ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  /* Handle */
  .tableScroll1 ::-webkit-scrollbar-thumb {
    background: #7f7f7f;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .tableScroll1 ::-webkit-scrollbar-thumb:hover {
    background: #7f7f7f;
  }
  